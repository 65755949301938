
export default {

    verifyAuth() {
        let token = localStorage.getItem('Authorization')
        if (token === null || token === '') {
            // next('/login')
            console.log("no token")
            window.location = "https://cn.hongkezhang.com/html/auth/login.html?oriHref=" + window.location.href
        } else
            return true;
    }

}