<template>
  <div>

  </div>
</template>

<script>

export default {
  name: 'HeadLine'
}

</script>

<style>

.head-item {
  line-height: 30px;
  font-size: 18px;
  margin-bottom: 15px;
}

.head-item a {
  color: #2c3e50;
  letter-spacing: 2px;
}

.head-item a:hover {
  color: #27ba9b;
}

.head-item::first-letter {
  color: #27ba9b;
  font-weight: bold;
}

</style>