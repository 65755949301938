import {MessageBox} from 'element-ui'

export default {

    showMessageRightTopVanish(obj, type, msg) {
        obj.$message({
            type: type,
            message: msg
        })
    },

    rightNotify(_this, title, msg, type, custClass) {
        _this.$notify({
            title: title,
            message: msg,
            duration: 2000,
            type: type,
            customClass: custClass
        });
    }

}