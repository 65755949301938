import http from '@/request/http'

export default {

    async makeIdPhoto(params) {
        let data
        await http.post("/ai/idphoto/make", params).then(response => {
            data = response.data
            console.log("idphoto api res data: " + data)
        })
        return data
    }

}