export default {

    imgToCanvas(img) {
        let canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        return canvas
    },

    waterImgToCanvas(canvas, img, btm, right) {
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, canvas.width - right - img.width, canvas.height - btm - img.height)
        return canvas
    },

    waterTxt2Canvs(canvas, text, bottom, right, style) {
        let ctx = canvas.getContext('2d')
        // 设置填充字号和字体，样式
        // ctx.fontSize = style.fontSize
        // ctx.fontFamily = "宋体"
        ctx.font = style.font
        ctx.fillStyle = style.color
        ctx.globalAlpha = style.opacity
        // console.log("style opacity: " + style.opacity)
        // 设置右对齐
        ctx.textAlign = 'right'
        // 在指定位置绘制文字，这里指定距离右下角20坐标的地方
        // console.log("ctx.measureText(text).width: " + ctx.measureText(text.replace(" ", "")).width)
        ctx.fillText(text, canvas.width - right, canvas.height - bottom)
        return canvas
    },

    waterTxt2Canvss(canvas, text, bottom, right) {
        return new Promise((resolve, reject) => {
            let ctx = canvas.getContext('2d')
            // 设置填充字号和字体，样式
            ctx.font = "24px 宋体"
            ctx.fillStyle = "#FFC82C"
            // 设置右对齐
            ctx.textAlign = 'right'
            // 在指定位置绘制文字，这里指定距离右下角20坐标的地方
            ctx.fillText(text, canvas.width - 20, canvas.height - 20)
            canvas.toBlob(blob => resolve(blob))
            return canvas
        })
    }

}

