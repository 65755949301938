import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import {Dialog, Message, MessageBox} from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import contentmenu from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'

import './index.scss'
import EnlargeImage from './components/magnifier/enlarge-image'

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

Vue.config.productionTip = false

Vue.use(ElementUI, {locale})
Vue.use(Dialog)
Vue.use(contentmenu)
Vue.use(EnlargeImage)

new Vue({
    router,
    render: h => h(App)
}).$mount(' #app')