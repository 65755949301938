<template>
  <div id="app">
    <div style="width: 95%; margin: 0px auto; position: relative; ">
      <div style="float:left;   ">
        <a href="/" style=" font-size: 30px; color: #014b85; ">
          <b style="color: #42B983; ">道</b>
          <b style="color: #2c3e50; ">森</b>
        </a>
      </div>
      <div style="float: left; margin-left: 20px;  " >
        <el-menu :default-active="activeIndex"
                 router
                 mode="horizontal"
                 background-color="#ffffff"
                 active-text-color="#2c3e50"
                 @select="handleSelect" style="text-decoration: none;">
          <el-submenu index="1">
            <template slot="title">AI 工具</template>
<!--            <el-menu-item index="/ai-card" @click="showCurr('1-1')">AI 证件照</el-menu-item>-->
            <el-menu-item index="/water-mark" @click="showCurr('1-2')">水印工具</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">技术工具</template>
            <el-menu-item index="/jihuo" @click="showCurr('2-1')">激活教程</el-menu-item>
            <el-menu-item index="/idea-jihuo" @click="showCurr('2-2')">idea-激活教程</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">博客文章</template>
            <el-menu-item index="/blog-list" @click="showCurr('3-1')">文章列表</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div id="loginInfo" style="position: absolute; right: 0px; top: 0px;">
        <div id="logged" style="display: none; color: #0A0A0B; ">
          <div style="display: inline-block; margin-right: 5px; ">
            <span>您好，</span><span id="userName"></span>
          </div>
          <div style="display: inline-block; cursor: pointer; " @click="goUserPage()">
            <svg t="1716278761002" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5643" width="28" height="28"><path d="M511.333 63.333c-247.424 0-448 200.576-448 448s200.576 448 448 448 448-200.576 448-448-200.576-448-448-448z m0 832c-51.868 0-102.15-10.144-149.451-30.15-36.011-15.231-69.123-35.67-98.812-60.897 12.177-31.985 42.226-63.875 84.223-88.903C396.189 686.243 456.222 669.53 512 669.53c55.631 0 115.416 16.658 164.026 45.703 41.762 24.953 71.689 56.812 83.863 88.804-29.764 25.342-62.976 45.865-99.106 61.146-47.299 20.006-97.582 30.15-149.45 30.15z m296.268-139.658c-20.493-35.937-54.353-68.855-98.747-95.381C649.75 624.979 579.839 605.53 512 605.53c-67.964 0-138.094 19.488-197.471 54.875-44.644 26.606-78.656 59.594-99.195 95.586-23.835-28.755-43.234-60.652-57.85-95.208-20.006-47.3-30.15-97.583-30.15-149.451s10.144-102.15 30.15-149.451c19.337-45.719 47.034-86.792 82.321-122.078 35.286-35.287 76.359-62.983 122.078-82.321 47.3-20.006 97.583-30.15 149.451-30.15 51.868 0 102.15 10.144 149.451 30.15 45.719 19.337 86.792 47.034 122.078 82.321 35.287 35.286 62.983 76.359 82.321 122.078 20.006 47.3 30.15 97.583 30.15 149.451s-10.144 102.15-30.15 149.451c-14.563 34.429-33.869 66.22-57.583 94.892z" fill="" p-id="5644"></path><path d="M512 220.223c-88.224 0-160 71.776-160 160s71.776 160 160 160c88.225 0 160-71.775 160-160s-71.775-160-160-160z m0 256c-52.935 0-96-43.065-96-96s43.065-96 96-96 96 43.065 96 96-43.065 96-96 96z" fill="" p-id="5645"></path></svg>
            <path d="M511.333 63.333c-247.424 0-448 200.576-448 448s200.576 448 448 448 448-200.576 448-448-200.576-448-448-448z m0 832c-51.868 0-102.15-10.144-149.451-30.15-36.011-15.231-69.123-35.67-98.812-60.897 12.177-31.985 42.226-63.875 84.223-88.903C396.189 686.243 456.222 669.53 512 669.53c55.631 0 115.416 16.658 164.026 45.703 41.762 24.953 71.689 56.812 83.863 88.804-29.764 25.342-62.976 45.865-99.106 61.146-47.299 20.006-97.582 30.15-149.45 30.15z m296.268-139.658c-20.493-35.937-54.353-68.855-98.747-95.381C649.75 624.979 579.839 605.53 512 605.53c-67.964 0-138.094 19.488-197.471 54.875-44.644 26.606-78.656 59.594-99.195 95.586-23.835-28.755-43.234-60.652-57.85-95.208-20.006-47.3-30.15-97.583-30.15-149.451s10.144-102.15 30.15-149.451c19.337-45.719 47.034-86.792 82.321-122.078 35.286-35.287 76.359-62.983 122.078-82.321 47.3-20.006 97.583-30.15 149.451-30.15 51.868 0 102.15 10.144 149.451 30.15 45.719 19.337 86.792 47.034 122.078 82.321 35.287 35.286 62.983 76.359 82.321 122.078 20.006 47.3 30.15 97.583 30.15 149.451s-10.144 102.15-30.15 149.451c-14.563 34.429-33.869 66.22-57.583 94.892z" fill="" p-id="5644"></path><path d="M512 220.223c-88.224 0-160 71.776-160 160s71.776 160 160 160c88.225 0 160-71.775 160-160s-71.775-160-160-160z m0 256c-52.935 0-96-43.065-96-96s43.065-96 96-96 96 43.065 96 96-43.065 96-96 96z" fill="" p-id="5645"></path></svg>
          </div>
        </div>
<!--        <div id="notLogged" style="display: block; ">-->
<!--          <el-button type="primary" round @click="goLoginTop()">登录 / 注册</el-button>-->
<!--        </div>-->
        <div id="logout" style="display: none; ">
          <span><a href="javascript:void(0)" @click="loginout()" style="color: #0A0A0B; ">[退出]</a></span>
        </div>
      </div>
    </div>
    <div style="clear: left; margin-top: 20px; ">
      <div style="margin: 20px 0; ">
        <router-view />
      </div>
    </div>

    <Foot />

<!--    <div style="clear: both; overflow: hidden; width: 100%;  margin-top: 10px; ">-->
<!--      <div style="clear: both; overflow: hidden; width: 80%; margin: 50px auto;  ">-->
<!--        <div class="">-->
<!--          <p style="text-align: center; font-family: 微软雅黑; color: #cccccc; font-weight: normal; ">-->
<!--            Copyright &copy;{{ copyYear }} All rights reserved <a href="https://beian.miit.gov.cn/" style="color: #cccccc; text-decoration: none; "><span id="beian-no">蜀ICP备2022004301号-1</span></a>-->
<!--          </p>-->
<!--&lt;!&ndash;          <p style="color: #cccccc; font-size: 14px; text-align: center; ">申明：本工具版权由即刻编程网@<a style="text-decoration: none; color: #cccccc;" href="https://cn.hongkezhang.com">cn.hongkezhang.com</a>所有。</p>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>

import Public from "@/api/Public";
import AICard from "@/views/AiCard";
import HeadLine from "@/views/HeadLine.vue";
import Foot from "@/views/Foot.vue";

export default {
  components: {Foot, HeadLine, AICard},
  data() {
    return {
      activeIndex: '/water-mark',
      copyYear: ''
    }
  },
  created() {
    this.render2ActiveRoute()
  },
  mounted() {
    this.render2ActiveRoute()
  },
  updated() {
    this.render2ActiveRoute()
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    showCurr(index) {
      if (index == '1-1') {
        this.activeIndex = '/ai-card'
      }
      if (index == '1-2') {
        this.activeIndex = '/water-mark'
      }
      if (index == '2-1') {
        this.activeIndex = '/jihuo'
      }
      if (index == '2-2') {
        this.activeIndex = '/idea-jihuo'
      }
    },
    loadUserInfo() {
      // Public.auth()
      console.log("load user info begin")
      var userName = localStorage.getItem("userName")
      console.log("userName: " + userName)
      if (userName == undefined || userName == "") {
        return ;
      }
      document.getElementById("logged").style.display = "block";
      document.getElementById("logout").style.display = "block";
      document.getElementById("notLogged").style.display = "none";
      document.getElementById("userName").innerHTML = userName

    },
    loginout() {
      let params = {}
      Public.loginout(params).then(res => {
        console.log("登出成功")
        localStorage.removeItem("userName")
        localStorage.removeItem("token")
        localStorage.removeItem("Authorization")
        document.getElementById("logged").style.display = "none";
        document.getElementById("logout").style.display = "none";
        document.getElementById("notLogged").style.display = "block";
      })
    },
    goLoginTop() {
      // usrOprtPrs("顶部点击登录")
      window.location =  "/html/auth/login.html" + "?oriHref=" + window.location.href
    },
    goUserPage() {
      window.location =  "/personal-page/#/basic-info"
    },
    createMeta() {
      document.createElement("meta").keys = ["免费给图片打水印", "图片打水印"]
    },
    render2ActiveRoute() {
      let url = window.location.href
      console.log("url: " + url)
      if (url.endsWith("#/")) {
        this.$router.push("/water-mark")
        return ;
      }
      if (url.endsWith("/ai-card")) {
        this.activeIndex = "/ai-card"
        return ;
      }
      if (url.endsWith("/water-mark")) {
        this.activeIndex = "/water-mark"
        return ;
      }
      if (url.endsWith("/jihuo")) {
        this.activeIndex = "/jihuo"
        return ;
      }
      if (url.endsWith("/idea-jihuo")) {
        this.activeIndex = "/idea-jihuo"
        return ;
      }
      if (url.endsWith("/blog-list")) {
        this.activeIndex = "/blog-list"
        return ;
      }
      // 默认路径
      this.$router.push("/ai-card")
    }
  }
}

</script>

<style>

a {
  text-decoration: none;
}

</style>