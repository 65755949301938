<template>
  <div style=" margin-top: 15px; ">
    <div @click="selTemp(1)" style="display:inline-block; position: relative; width: 200px; height: 150px; border: #062c33 dashed 1px; border-radius: 5px; ">
      <div style="width: 90%; margin: 5px auto; cursor: pointer; ">
        <p>右下角</p>
        <div><span style="">距右侧：</span><el-input v-model="pos.right" type="text" style="width: 60px; " /> px</div>
        <div style="margin-top: 5px; "><span style="">距下方：</span><el-input v-model="pos.bottom" type="text" style="width: 60px; " /> px</div>
        </div>
      <div id="sel1" style="display: none; "><i class="el-icon-success" style="position: absolute; right: 5px; bottom: 5px; color: green; font-weight: bold; "></i></div>
      <div><i class="el-icon-circle-check" style="position: absolute; right: 5px; bottom: 5px;  font-weight: bold; "></i></div>
    </div>
    <div @click="selTemp(2)" style="display:inline-block; margin-left: 10px; position: relative; width: 200px; height: 150px; border: #062c33 dashed 1px; border-radius: 5px; ">
      <div style="width: 90%; margin: 5px auto; cursor: pointer; ">
        <p>左下角</p>
        <div><span style="">距左侧：</span><el-input v-model="pos.left" type="text" style="width: 60px; " /> px</div>
        <div style="margin-top: 5px; "><span style="">距下方：</span><el-input v-model="pos.bottom" type="text" style="width: 60px; " /> px</div>
        </div>
      <div id="sel2" style="display: none; "><i class="el-icon-success" style="position: absolute; right: 5px; bottom: 5px; color: green; font-weight: bold; "></i></div>
      <i class="el-icon-circle-check" style="position: absolute; right: 5px; bottom: 5px;  font-weight: bold; "></i>

    </div>
    <div @click="selTemp(3)" style="display:inline-block; position: relative;  margin-left: 10px; width: 200px; height: 150px; border: #062c33 dashed 1px; border-radius: 5px; ">
      <div style="width: 90%; margin: 5px auto; cursor: pointer; ">
        <p>右上角</p>
        <div><span style="">距右侧：</span><el-input v-model="pos.right" type="text" style="width: 60px; " /> px</div>
        <div style="margin-top: 5px; "><span style="">距上方：</span><el-input v-model="pos.top" type="text" style="width: 60px; " /> px</div>
        <div id="sel3" style="display: none; "><i class="el-icon-success" style="position: absolute; right: 5px; bottom: 5px; color: green; font-weight: bold; "></i></div>
        <i class="el-icon-circle-check" style="position: absolute; right: 5px; bottom: 5px; font-weight: bold; "></i>
      </div>
    </div>

    <div @click="selTemp(4)" style="display:inline-block; position: relative; margin-left: 10px; width: 200px; height: 150px; border: #062c33 dashed 1px; border-radius: 5px; ">
      <div style="width: 90%; margin: 5px auto; cursor: pointer; ">
        <p>左上角</p>
        <div><span style="">距左侧：</span><el-input v-model="pos.left" type="text" style="width: 60px; " /> px</div>
        <div style="margin-top: 5px; "><span style="">距上方：</span><el-input v-model="pos.top" type="text" style="width: 60px; " /> px</div>
        </div>
      <div id="sel4" style="display: none; "><i class="el-icon-success" style="position: absolute; right: 5px; bottom: 5px; color: green; font-weight: bold; "></i></div>
      <i class="el-icon-circle-check" style="position: absolute; right: 5px; bottom: 5px; font-weight: bold; "></i>

    </div>

    <div @click="selTemp(5)" style="display:inline-block; position: relative; vertical-align: top;
    margin-left: 10px; width: 200px; height: 150px; border: #062c33 dashed 1px; border-radius: 5px; cursor: pointer">
      <div style="width: 90%; margin: 30% auto; text-align: center; cursor: pointer; ">
        <div style="display: block; ">居中</div>
      </div>
      <div id="sel5" style="display: none; "><i class="el-icon-success" style="position: absolute; right: 5px; bottom: 5px; color: green; font-weight: bold; "></i></div>
      <i class="el-icon-circle-check" style="position: absolute; right: 5px; bottom: 5px; font-weight: bold; "></i>

    </div>

  </div>

</template>

<script>

import MessageUtils from "@/utils/MessageUtils";

export default {
  name: 'MarkTemplate',
  data() {
    return {
      pos: {
        left: 20,
        right: 20,
        bottom: 20,
        top: 20
      },
      posSel: 1
    }
  },
  created() {
    this.selTemp(this.posSel)
  },
  mounted() {
    this.selTemp(this.posSel)
  },
  methods: {
    checkPos() {
      let type = this.posSel
      if (type == 1) {
        if (this.pos.bottom == '' || this.pos.right == '') {
          MessageUtils.showMessageRightTopVanish(this, "warn", "请检查右侧和下方的距离参数")
          return false
        }
      }
      if (type == 2) {
        if (this.pos.bottom == '' || this.pos.left == '') {
          MessageUtils.showMessageRightTopVanish(this, "warn", "请检查左侧和下方的距离参数")
          return false
        }
      }
      if (type == 3) {
        if (this.pos.right == '' || this.pos.top == '') {
          MessageUtils.showMessageRightTopVanish(this, "warn", "请检查右侧和上方的距离参数")
          return false
        }
      }
      if (type == 4) {
        if (this.pos.left == '' || this.pos.top == '') {
          MessageUtils.showMessageRightTopVanish(this, "warn", "请检查左侧和上方的距离参数")
          return false
        }
      }
      return true
    },
    getPos(imgWidth, imgHeight, type, wordsWidth, wordsHeight) {
      console.log("pos type: " + type)
      let res = {}
      if (type == 1) {
        let right = this.pos.right
        let bottom = this.pos.bottom
        res.right = right
        res.bottom = bottom
        return res
      }
      if (type == 2) {
        let left = this.pos.left
        let bottom = this.pos.bottom
        res.right = imgWidth - left - wordsWidth
        res.bottom = bottom
        return res
      }
      if (type == 3) {
        let right = this.pos.right
        let top = this.pos.top
        res.right = right
        res.bottom = imgHeight - top - wordsHeight
        return res
      }
      if (type == 4) {
        let left = this.pos.left
        let top = this.pos.top
        res.right = imgWidth - left - wordsWidth
        res.bottom = imgHeight - top - wordsHeight
        return res
      }
      if (type == 5) {
        res.right = (imgWidth - wordsWidth) / 2
        res.bottom = (imgHeight - wordsHeight) / 2
        return res
      }
    },
    getSelTemp() {
      return this.polSel
    },
    selTemp(num) {
      console.log("click: " + num)
      this.polSel = num
      console.log("pol sel: " + this.polSel)
      for (var i = 1; i <= 5; i++) {
        document.getElementById("sel" + i).style.display = "none"
      }
      document.getElementById("sel" + num).style.display = "block"
    }
  }

}

</script>