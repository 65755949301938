<template>
  <div>
    <div style="margin: 25px 0; text-align: center; " >
      <div style="width: 80%; margin: 0 auto; text-align: left; ">
        <div style="text-align: center; ">
          <p style="font-size: 30px; "><b style="color: darkgreen; ">免费</b>在线证件照制作工具</p>
          <p>为用户提供多种需求下的证件照制作功能，在线免费使用，立即开始制作吧</p>
        </div>

        <div style="display: none; height: 350px; " >
          <input type="file" accept=".png,.jpg,.jpeg" ref="myWaterFile" @change="previewWaterFile" />
        </div>
        <div style="text-align: center; " v-loading="this.makePhotoLoading">
          <div style="margin-top: 50px; text-align: center; ">
            <div v-if="this.type == 0 && imageWater == ''"
                 style="width: 50%; height: auto; margin-left: 25%; position: relative; cursor: pointer;  " @click="showWaterSelBoxS"
                 >
              <el-image src="static/kuang.png" >

              </el-image>
              <el-image src="static/plus.png" style="width: 20%; height: 40%; position: absolute; left: 40%; top: 30%; ">

              </el-image>
            </div>
          </div>
          <div id="waterBox" v-if="this.type == 0 && imageWater != ''" style=" margin-top: 50px;   " >
<!--            <div style="border-top: 2px #64c5ff solid; "></div>-->
            <img id="imageWater" :src="imageWater"
                      style=" height: auto; border: 1px #2c3e50 dashed; cursor: pointer; "
                      class="imageWaterC" @click="showWaterSelBoxS"/>
          </div>
          <div v-if="type == 1" style="display: block; margin-top: 10px; " >
            <el-image id="" :src="imageOutWater"
                      style=" width: 33%; height: auto; border: 1px dashed #2c3e50"
                      class="imageWaterC">
            </el-image>
          </div>
          <div style="background-color: #64c5ff; width: 50%; margin-left: 25%; border-radius: 10px;  ">
            <p style="font-size: 35px; padding: 5px;  ">导入图片支持扩展名：jpg、jpeg、png</p>
          </div>
        </div>
        <div style="text-align: center; margin-top: 50px;">
          <div style=" display: inline-block; margin-right: 20px; ">
            <p style="font-size: 20px; line-height: 50px; ">选择证件照背景颜色</p>
          </div>
          <div style="display: inline-block; ">
            <div class="clo-picker" style=" background-color: #438edb; " @click="selCol(1, '(219,142,67)')">
              <div style="line-height: 50px; ">
                <i v-if="colCk == 1" class="el-icon-check my-ck"/>
              </div>
            </div>
            <div class="clo-picker" style=" background-color: #ff0000; " @click="selCol(2, '(0,0,255)')">
              <div style="line-height: 50px; ">
                <i v-if="colCk == 2" class="el-icon-check my-ck"/>
              </div>
            </div>
            <div class="clo-picker" style=" background-color: #ffffff; " @click="selCol(3, '(255,255,255)')">
              <div style="line-height: 50px; ">
                <i v-if="colCk == 3" class="el-icon-check my-ck"/>
              </div>
            </div>
            <div class="clo-picker" style=" background-color: #338fb2;  " @click="selCol(4, '(178,143,51)')">
              <div style="line-height: 50px; ">
                <i v-if="colCk == 4" class="el-icon-check my-ck"/>
              </div>
            </div>
            <div class="clo-picker" style=" background-color: #64c5ff;  " @click="selCol(5, '(255,197,100)')">
              <div style="line-height: 50px; ">
                <i v-if="colCk == 5" class="el-icon-check my-ck"/>
              </div>
            </div>
            <div class="clo-picker" style=" background-color: #e3e5e5;  " @click="selCol(6, '(229,229,227)')">
              <div style="line-height: 50px; ">
                <i v-if="colCk == 6" class="el-icon-check my-ck"/>
              </div>
            </div>
          </div>
        </div>

        <div style="text-align: center; margin-top: 50px; text-align: center; ">
          <div class="size-ck" id="cSize-1" @click="cSize(1, '( 413,295)')" style="width: 150px; height: 80px; text-align: center; border: 1px #42B983 solid; border-radius: 3px; ">
            <div style="padding: 5px; ">
              <span>一寸</span><br/>
              <span>295 x 413 px</span><br/>
              <span>25 x 35 mm</span>
            </div>
          </div>
          <div class="size-ck" id="cSize-2" @click="cSize(2, '(579,413)')" style="width: 150px; height: 80px; text-align: center; border: 1px #42B983 solid; border-radius: 3px; ">
            <div style="padding: 5px; ">
              <span>二寸</span><br/>
              <span>413 x 579 px</span><br/>
              <span>35 x 50 mm</span>
            </div>
          </div>
          <div class="size-ck" id="cSize-3" @click="cSize(3, '(378,260)')" style="width: 150px; height: 80px; text-align: center; border: 1px #42B983 solid; border-radius: 3px; ">
            <div style="padding: 5px; ">
              <span>小一寸</span><br/>
              <span>260 x 378 px</span><br/>
              <span>22 x 32 mm</span>
            </div>
          </div>
          <div class="size-ck" id="cSize-4" @click="cSize(4, '(531,413)')" style="width: 150px; height: 80px; text-align: center; border: 1px #42B983 solid; border-radius: 3px; ">
            <div style="padding: 5px; ">
              <span>小二寸</span><br/>
              <span>413 x 531 px</span><br/>
              <span>33 x 48 mm</span>
            </div>
          </div>
        </div>
        <div style="margin-top: 50px; text-align: center; ">
          <el-button type="primary" @click="makeIdPhoto()" style="background-color: #2c3e50; width: 300px; color: #ffffff; " round>
            <span style="letter-spacing: 3px; font-size: 25px; ">点击制作证件照</span>
          </el-button>
          <el-button type="primary" @click="dldNoWaterImgPre()" v-if="type == 1" style=" background-color: #27ba9b; width: 400px; color: #f8f8f8; " round>
            <span style="letter-spacing: 3px; font-size: 25px; ">下载高清无水印照片</span>
          </el-button>
        </div>
      </div>
      <div style="margin-top: 100px; ">
        <p style="font-size: 26px; ">拍摄须知：</p>
        <div class="shoot">
          <el-image src="static/shoottip/sunshine.png"/>
          <p>光线充足</p>
        </div>
        <div class="shoot">
          <el-image src="static/shoottip/深色衣服.png"/>
          <p>深色衣服</p>
        </div>
        <div class="shoot">
          <el-image src="static/shoottip/耳朵.png"/>
          <p>露出耳朵</p>
        </div>
        <div class="shoot">
          <el-image src="static/shoottip/肩膀.png"/>
          <p>露出肩膀</p>
        </div>
      </div>
      <div id="verifyDialog" style="position: fixed; top: 50px; width: 60%; height: auto; background-color: #ffffff; margin-left: 20%;
       border: 2px #338fb2 solid; border-radius: 25px; display: none; " >
        <i class="el-icon-close" style="position: absolute; right: 15px; top: 15px; font-size: 40px;
        color: #000000; cursor: pointer; " @click="closeVerify"/>
        <p style="margin-top: 20px; font-size: 26px; ">微信扫码</p>
        <div>
          <img src="static/qr-dosen.jpg">
        </div>
        <div>
          <span style="font-size: 20px; ">扫码关注公众号，发送“验证码”，获取下载权限。</span>
          <span style="font-size: 20px; "></span>
          <el-input class="verify-input" placeholder="请输入4位验证码" v-model="verifyCode" style="width: 300px; height: 50px;font-size: 20px;  border-radius: 50px; " />
        </div>
        <div style="margin-bottom: 15px; ">
          <el-button type="primary" round @click="verifyD" style="width: 100px; ">提交</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import ImageUtil from "@/utils/ImageUtil";
import IdPhoto from "@/api/IdPhoto";
import MessageUtils from "@/utils/MessageUtils";
import Public from "@/api/Public";
import AuthUtil from "@/utils/AuthUtil";

export default {
  name: "AICard",
  data() {
    return {
      type: 0,
      canClickMake: false,
      imageWater: '',
      imageOutWater: '',
      imageOutNoWater: '',
      makePhotoLoading: false,
      bgColor: '(219,142,67)',
      pSize: '(413,295)',
      bgImage:'url(' + require('../static/kuang.png') + ')',
      colCk: 1,
      dialogVisible: true,
      verifyCode: ''
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    stopLoading() {
      this.makePhotoLoading = false
    },
    verifyD() {
      if (this.verifyCode != '6780') {
        MessageUtils.rightNotify(this, "提示", "验证码错误", 'warning')
        return false
      }
      this.dldNoWaterImg()
    },
    closeVerify() {
      document.getElementById("verifyDialog").style.display = "none"
    },
    init() {
      document.getElementById("cSize-1").style.backgroundColor = '#cccccc'
    },
    cSize(num, size) {
      this.pSize = size
      for (let i = 0; i < 4; i++) {
        if ( (i + 1) == num) {
          document.getElementById("cSize-" + (i + 1)).style.backgroundColor = '#cccccc'
        } else {
          document.getElementById("cSize-" + (i + 1)).style.backgroundColor = '#ffffff'
        }
      }
    },
    selCol(num, col) {
      this.colCk = num
      this.bgColor = col
    },
    authVerify() {
      if (AuthUtil.verifyAuth())
      {
        return true
      } else {
        MessageUtils.showMessageRightTopVanish(this, "warn", "请先登录再使用")
        return false
      }
    },
    dldNoWaterImgPre() {
      let params = {}
      params.oprtType = "在AI证件照界面点击下载无水印证件照，要求用户进行验证"
      Public.usrOprt(params).then(res => {
        console.log("调用用户操作接口成功")
      })
      // 打开对话框
      document.getElementById("verifyDialog").style.display = 'block'
    },
    dldNoWaterImg() {
      let params = {}
      params.oprtType = "用户已通过验证，下载成功"
      Public.usrOprt(params).then(res => {
        console.log("调用用户操作接口成功")
      })
      // if ( ! this.authVerify()) {
      //   params.oprtType = "用户未登录，跳转登录页"
      //   Public.usrOprt(params).then(res => {
      //     console.log("调用用户操作接口成功")
      //   })
      //   return false
      // }
      let images = []
      const image = {}
      image.imageStr = this.imageOutNoWater
      images.push(image)
      ImageUtil.downloadImages(images, ImageUtil)
    },
    showWaterSelBoxS() {
      this.$refs.myWaterFile.click()
      this.$refs.myWaterFile.value = ""
    },
    verifyType() {
      let params = {}
      params.oprtType = "用户在AI证件照界面点击制作证件照"
      Public.usrOprt(params).then(res => {
        console.log("调用用户操作接口成功")
      })
      if ( ! this.canClickMake) {
        MessageUtils.rightNotify(this, "提示", "请上传符合规范照片", 'warning')
        return false
      }
      return true
    },
    getBgColor(params) {
      /// 这个地方应该是后端AI工具的问题，这里只是一个暂时的解决办法
      if (this.bgColor == '3') {
        params.rgbColor = "(0,0,255)"
      }
      if (this.bgColor == '2') {
        params.rgbColor = "(255,255,255)"
      }
      if (this.bgColor == '1') {
        params.rgbColor = "(255,0,0)"
      }
      if (this.bgColor == '4') {
        params.rgbColor = "(169,169,169)"
      }
    },
    getSize(params) {
      if (this.pSize == '1') {
        params.size = "(413,295)"
      }
      if (this.pSize == '2') {
        params.size = "(378,260)"
      }
      if (this.pSize == '3') {
        params.size = "(579,413)"
      }
      if (this.pSize == '4') {
        params.size = "(513,413)"
      }
    },
    makeIdPhoto() {
      if ( ! this.verifyType()) {
        return false
      }
      let params = {}
      params.inFile = this.imageWater
      params.rgbColor = this.bgColor
      params.size = this.pSize
      console.log("color: " + params.rgbColor)
      console.log("size: " + params.size)
      this.makePhotoLoading = true
      IdPhoto.makeIdPhoto(params).then(res => {
        console.log("res data: " + res)
        if (res == undefined || res == '') {
          this.makePhotoLoading = false
          MessageUtils.rightNotify(this, "提示", "请上传符合规范照片", 'error')
          return false
        }
        const json = JSON.parse(res.msg)
        this.imageOutNoWater = "data:image/png;base64," + json.outFileNoWaterByte
        console.log("imageOutNoWater: " + this.imageOutNoWater)
        this.imageOutWater = "data:image/png;base64," + json.outFileWithWaterByte
        this.type = 1
        this.makePhotoLoading = false
      })

    },
    previewWaterFile() {
      let file = this.$refs.myWaterFile.files[0]
      if (file == undefined) {
        console.error("file is empty")
        return ;
      }
      let img = new FileReader()
      img.readAsDataURL(file)
      let imageWaterItem = {}
      img.onload = ({ target }) => {
        this.imageWater = target.result
        console.log("img: ", this.imageWater)
        // document.getElementById("waterBox").style.display = 'block'
        // document.getElementById("makeCard").style.display = 'block'
        this.canClickMake = true
      }
    },
  }
}

</script>

<style>

.clo-picker {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 5px;
  border: 1px #cccccc solid;
  cursor: pointer;
}

#waterBox {
  background-image: url("../static/kuang.png") center no-repeat;
}

.my-ck {
  color: #f8f8f8; font-size: 25px; font-weight: bold;
}

.shoot {
  display: inline-block;
  margin-right: 50px;
  margin-left: 50px;
}

.shoot p {
  font-size: 20px;
}

.size-ck {
  cursor: pointer;
  display: inline-block;
  margin-left: 50px;
  margin-right: 50px;
}

.size-ck:hover {
  background-color: #cccccc;
  cursor: pointer;
}

.verify-input:focus {
  border-color: #409EFF;
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.5);
}

</style>