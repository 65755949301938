import JSZip from 'jszip'
import {saveAs} from 'file-saver'

export default {

    downloadImages(imageDatas, _this) {
        var zip = new JSZip();
        var count = 0;
        imageDatas.forEach((image, index) => {
            var imgURL = image.imageStr
            console.log("imageURL: " + imgURL)
            var imgName = index + ".png"
            imgName = imgName.split("?")[0]; // 去除URL中的参数
            var blobImg = _this.base64ToBlob(imgURL)
            zip.file(imgName, blobImg); // 将图片添加到zip文件中
            count++;

        });
        console.log("count: " + count)
        if (count === imageDatas.length) {
            zip.generateAsync({type: "blob"}).then(function (content) {
                saveAs(content, "images.zip"); // 下载zip文件
            });
        }
    },

    base64ToBlob(code) {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {
            type: contentType
        });
    },

    async getSizeOfImage(url) {
        return new Promise(function (resolve, reject) {
            var img = new Image();
            // 设置图片的 src 属性为图片的 URL
            img.src = url;
            // 当图片加载完成后执行回调函数
            let res = {}

            img.onload = function () {
                // 获取图片的宽度和高度
                res.width = img.width;
                res.height = img.height;
                // 输出图片的宽度和高度
                // console.log("图片的宽度：" + res.width);
                // console.log("图片的高度：" + res.height);
                resolve(res)
            };
        })

    }

}