<template>
  <div>
    <div style="margin: 25px 0; text-align: center; " >
      <div style="width: 80%; margin: 0 auto; text-align: left; ">
        <div style="text-align: center; ">
          <p style="font-size: 30px; "><b style="color: darkgreen; ">免费</b>在线打水印工具</p>
          <p>本工具为用户提供给图片打水印功能，在线免费使用</p>
        </div>
        <p style="font-size: 30px; text-align: center; ">让我们开始吧！</p>
        <div id="topAutoFixedBox" style="margin-top: 30px; ">
          <div style="text-align: center;  ">
            <el-input class="waterText-input" type="text" placeholder="输入水印文字" v-model="markWords" style="width: 250px; margin-right: 10px; "/>
            <div style="display: inline-block; margin-left: 10px;" >文字大小：</div>
            <el-select style="width: 100px; " v-model="markStyle.fontSize" placeholder="请选择">
              <el-option
                  v-for="item in fontOps"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div style="display: inline-block; margin-left: 10px;" >水印透明度：</div>
            <el-select style="width: 100px; " v-model="markStyle.opacity" placeholder="请选择">
              <el-option
                  v-for="item in opacityOps"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--            <el-input type="text" placeholder="输入文字大小" v-model="markStyle.fontSize" style="margin-left: 10px; width: 200px; "/>-->
            <!--            <div style="position: relative; width: 230px; display: inline-block; ">-->
            <!--&lt;!&ndash;              <el-input v-model="selColor" style=" margin-left: 10px;  ">&ndash;&gt;-->

            <!--&lt;!&ndash;              </el-input>&ndash;&gt;-->
            <!--            </div>-->
            <div style="display: inline-block; margin-left: 10px;" >颜色选择：</div>
            <div style="display: inline-block;  vertical-align: top;">

              <el-color-picker
                  style=" "
                  v-model="selColor"
                  show-alpha
                  :predefine="predefineColors">
              </el-color-picker>
            </div>

          </div>
          <div style="text-align: center; ">
            <mark-template ref="markTemplate"></mark-template>
          </div>
        </div>

        <div style="display: none; " >
          <input type="file" accept=".png,.jpg,.jpeg" ref="myWaterFile" multiple @change="previewFiles" />
        </div>
        <div style="position: relative; margin-top: 50px; text-align: center; ">
          <div style="width: 50%; height: auto; margin-left: 25%; position: relative; cursor: pointer;  "
               @click="attachFile2CurrDoc">
            <el-image src="static/kuang.png" >
            </el-image>
            <el-image src="static/plus.png" style="width: 20%; height: 40%; position: absolute; left: 40%; top: 30%; ">
            </el-image>
          </div>
        </div>
        <div>
          <div style="background-color: #64c5ff; width: 50%; margin-left: 25%; border-radius: 10px; text-align: center; ">
            <p style="font-size: 35px; padding: 5px;  ">一次性可导入20张图片，支持jpg、jpeg、png</p>
          </div>
        </div>
        <div style="float: left; margin-bottom: 30px; width: 100%; ">
          <div @click="biggerView(item.imageStr, index)" v-for="(item, index) in imageDataList" style=" ">
            <div class="img-item-box" >
              <div style="position: relative; ">
                <div style="text-align: center; height: 30px; line-height: 30px; ">
                  <span style="color: #338fb2; font-weight: bold; ">{{ index + 1 }}</span>

                  <div style="position: absolute; cursor: pointer; right: 5px; top: 0px; "
                       @click="deleteCurrPage(index)"><i class="el-icon-close" /></div>
                </div>
                <!--          <div v-if="(item.height / item.width) > 1.4" @click="biggerView(item.imageStr, index)" style="overflow: hidden; height: 500px; ">-->
                <!--            <enlarge-image  v-if="showEnlarge" :image="item.imageStr" :styles="`background-color: #ffffff;-->
                <!--          height: auto; width: 100%; z-index: 1000; cursor: pointer; border: 1px #000000 solid; `" class="pdf-image-box">-->
                <!--            </enlarge-image>-->
                <!--            <el-image v-else style=" background-color: #ffffff; " :src="item.imageStr" class="pdf-image-box" >-->
                <!--            </el-image>-->
                <!--            <div style="text-align: center; margin-top: 10px; ">图片实际尺寸：长：{{ item.width }}px 宽：{{ item.height }}px</div>-->

                <!--          </div>-->
                <div style="text-align: center; ">
                  <el-image :src="item.imageStr" class="pdf-image-box"
                            :preview-src-list="imageDataSrcList"/>
                  <!--                  <enlarge-image  v-if="showEnlarge" :image="item.imageStr" :styles="`background-color: #ffffff;-->
                  <!--          height: auto; width: 100%; z-index: 1000; cursor: pointer; max-height: 450px; overflow: hidden;`" class="pdf-image-box">-->
                  <!--                  </enlarge-image>-->
                  <!--                  &lt;!&ndash;            <el-image v-else style=" background-color: #ffffff;overflow: hidden; " :src="item.imageStr" class="pdf-image-box" >&ndash;&gt;-->
                  <!--                  &lt;!&ndash;            </el-image>&ndash;&gt;-->
                  <div style="text-align: center; height: 30px; ">
                    <p>
                      实际尺寸
                    </p>
                    <p>
                      宽：{{ item.width }}px，高：{{ item.height }}px
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both; text-align: center; margin-top: 30px; ">
          <el-tooltip v-if="marked == 1" class="item" effect="dark" content="抹去所有水印" placement="top">
            <i  class="el-icon-refresh-left" style="font-size: 30px; font-weight: bold; margin-right: 10px;
          cursor: pointer; " @click="reloadImage"/>
          </el-tooltip>

          <el-button type="primary" @click="doMarkPre()" style="background-color: #2c3e50; width: 300px; color: #ffffff; " round>
            <span style="letter-spacing: 3px; font-size: 25px; ">点击制作水印图片</span>
          </el-button>
          <el-button v-if="marked == 1" type="primary" @click="dldDocumentsPre()" style="background-color: #42B983; width: 400px; color: #ffffff; " round>
            <span style="letter-spacing: 3px; font-size: 25px; ">下载高清水印图片</span>
          </el-button>
        </div>
      </div>
    </div>
    <div id="verifyDialog" style="position: fixed; top: 50px; width: 60%; height: auto; background-color: #ffffff; margin-left: 20%;
       border: 2px #338fb2 solid; border-radius: 25px; display: none; text-align: center; " >
      <i class="el-icon-close" style="position: absolute; right: 15px; top: 15px; font-size: 40px;
        color: #000000; cursor: pointer; " @click="closeVerify"/>
      <p style="margin-top: 20px; font-size: 26px; ">微信扫码</p>
      <div>
        <img src="static/qr-dosen.jpg">
      </div>
      <div>
        <span style="font-size: 20px; ">扫码关注公众号，发送“水印1”，获取下载权限。</span>
        <span style="font-size: 20px; "></span>
        <el-input class="verify-input" placeholder="请输入4位验证码" v-model="verifyCode" style="width: 300px; height: 50px;font-size: 20px;  border-radius: 50px; " />
      </div>
      <div style="margin-bottom: 15px; ">
        <el-button type="primary" round @click="verifyD" style="width: 100px; ">提交</el-button>
      </div>
    </div>
    <!--    <div id="biggerBox" style=" z-index: 2500; border: #2c3e50 1px solid; display: none; position: fixed; top: 50px;-->
    <!--       width: 60%; height: auto; background-color: #ffffff; margin-left: 20%;-->
    <!--       border: 2px #338fb2 solid; border-radius: 25px; display: none; text-align: center; ">-->
    <!--      <div style="position: relative;  ">-->
    <!--        <span class="" @click="closeBiggerBox" style="position: absolute; right: 10px; top: 2px;-->
    <!--      font-size: 25px; cursor: pointer; ">X</span>-->
    <!--        <div style="text-align: center; color: #ffffff; height: 30px;-->
    <!--      line-height: 30px;font-size: 20px; background-color: #cccccc; ">Page {{pageIndex}}</div>-->
    <!--        <el-image :src="biggerViewSrc" class="" style="height: 100%; margin: 0px; display: block; " ></el-image>-->
    <!--      </div>-->

    <!--    </div>-->
  </div>
</template>


<script>

import MarkTemplate from "@/views/water-mark/MarkTemplate.vue";
import ImageUtil from "@/utils/ImageUtil";
import MessageUtils from "@/utils/MessageUtils";
import CanvasUtil from "@/utils/CanvasUtil";
import PublicApi from "@/api/Public";
import VerifyDialog from "@/views/verify/VerifyDialog.vue";
import ArrayUtil from "@/utils/ArrayUtil";
import CommonUtil from "@/utils/CommonUtil";

export default {
  components: {VerifyDialog, MarkTemplate},
  data() {
    return {
      marked: 0,
      markWords: '替换这里的水印文字',
      markStyle: {
        fontSize: '12',
        opacity: 1
      },
      fontOps: [
        {value: 12, label: "12px"}, {value: 16, label: "16px"}, {value: 20, label: "20px"}, {value: 24, label: "24px"}, {value: 48, label: "48px"}, {value: 72, label: "72px"}
      ],
      opacityOps: [
        {value: 0.5, label: "半透明"}, {value: 1, label: "不透明"},
        {value: 0.9, label: "0.9"}, {value: 0.8, label: "0.8"},{value: 0.7, label: "0.7"},
        {value: 0.6, label: "0.6"}, {value: 0.4, label: "0.4"},
        {value: 0.3, label: "0.3"}, {value: 0.2, label: "0.2"},{value: 0.1, label: "0.1"},
      ],
      selColor: '#000000',
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      bckImageDateList: [],
      imageDataList: [],
      imageDataMarkLogoList: [],
      imageDataSrcList: [],
      imageDataNoLogoList: [],
      showEnlarge: true,
      verifyCode: '',
      biggerViewSrc: '',
      imageProcessCount: 0,
      makeWaterFlag: true
    }
  },
  created() {
    // document.addEventListener("scroll", this.pageScroll); //监听页面滚动
  },
  mounted() {
    // document.addEventListener("scroll", this.pageScroll); //监听页面滚动
  },
  methods: {
    usrOprt(text) {
      let params = {}
      params.oprtType = text
      PublicApi.usrOprt(params).then(res => {
        console.log("调用用户操作接口成功")
      })
    },

    closeBiggerBox() {
      document.getElementById("biggerBox").style.display = "none"
    },
    biggerView(imageStr, index) {
      this.biggerViewSrc = imageStr
      document.getElementById("biggerBox").style.display = "block"
    },
    verifyD() {
      if (this.verifyCode != '7850') {
        MessageUtils.rightNotify(this, "提示", "验证码错误", 'warning')
        return false
      }
      this.dldDocuments()
    },
    closeVerify() {
      document.getElementById("verifyDialog").style.display = "none"
    },
    init() {
      document.getElementById("cSize-1").style.backgroundColor = '#cccccc'
    },
    reloadImage() {
      console.log("this.bckImageDateList length: " + this.bckImageDateList.length)
      const length = this.imageDataList.length
      for(let i = 0; i < length; i++) {
        this.imageDataList.pop()
      }
      for(let i = 0; i < length; i++) {
        this.imageDataList.push(JSON.parse(JSON.stringify(this.bckImageDateList[i])))
      }
      // ArrayUtil.emptyList(this.imageDataNoLogoList)
      this.marked = 0
    },
    pageScroll() {
      // if (this.timer) clearTimeout(this.timer)
      // this.timer = setTimeout(this.scroll(), 300)
      this.scroll()
    },
    showImageBigStr() {
      ArrayUtil.emptyList(this.imageDataSrcList)
      for(let i = 0; i < this.imageDataList.length; i++) {
        this.imageDataSrcList.push(this.imageDataList[i].imageStr)
      }
    },
    scroll() {
      this.$nextTick(() => {
        let scrollTop = window.scrollY
        console.log("scroll top: " + scrollTop)
        if (scrollTop >= 50) {
          // document.getElementById("topAutoFixedBox").style.position = "fixed"
          // document.getElementById("topAutoFixedBox").style.top = "0px"
        } else {
          // document.getElementById("topAutoFixedBox").style.position = "static"
        }
      })
    },
    dldDocumentsPre() {
      // this.$refs['verifyBox'].showVerifyBox()
      if(this.marked == 0) {
        MessageUtils.rightNotify(this, "警告", "请先给图片添加水印", "warning")
        return false
      }
      // 打开对话框
      document.getElementById("verifyDialog").style.display = 'block'
      // this.dldDocuments()
    },
    dldDocuments(){
      this.usrOprt("用户在文字水印界面开始下载图片")
      console.log("开始下载图片 this.imageDataNoLogoList: " + this.imageDataNoLogoList.length)
      // const imageDatas = this.imageDataList
      // const imageDatas = this.imageDataNoLogoList
      const imageDatas = this.imageDataList
      ImageUtil.downloadImages(imageDatas, ImageUtil)
    },
    doTxtMark(cvs, words, width, height) {
      let checkRes = this.$refs['markTemplate'].checkPos()
      if ( ! checkRes) {
        return false
      }
      let posSel = this.$refs['markTemplate'].getSelTemp()
      let style = {}
      style.font = this.markStyle.fontSize + "px " + "宋体"
      style.opacity = this.markStyle.opacity
      let ctx = cvs.getContext('2d')
      ctx.font = style.font
      let wordsWidth = ctx.measureText(words).width
      // console.log("words width: " + wordsWidth)
      let wordsHeight = this.markStyle.fontSize
      let res = this.$refs['markTemplate'].getPos(cvs.width, cvs.height, posSel, wordsWidth, wordsHeight)
      // return CanvasUtil.waterTxt2Canvs(cvs, waterImg, res.bottom, res.right)

      // console.log("bottom: " + res.bottom + ", right: " + res.right)
      let waterTextObj = document.getElementsByClassName("waterText-input")[0]
      // console.log("waterTextObj.clientHeight: " + waterTextObj.clientHeight + "")
      if (this.selColor == '') {
        this.selColor = "#cccccc"
      }
      style.color = this.selColor
      return CanvasUtil.waterTxt2Canvs(cvs, words, res.bottom, res.right, style)
    },
    doTxtMarkLogo(cvs, words, width, height) {
      let checkRes = this.$refs['markTemplate'].checkPos()
      if ( ! checkRes) {
        return false
      }
      let posSel = 5
      let style = {}
      style.font = 20 + "px " + "宋体"
      style.opacity = 0.7
      let ctx = cvs.getContext('2d')
      ctx.font = style.font
      let wordsWidth = ctx.measureText(words).width
      // console.log("words width: " + wordsWidth)
      let wordsHeight = this.markStyle.fontSize
      let res = this.$refs['markTemplate'].getPos(cvs.width, cvs.height, posSel, wordsWidth, wordsHeight)
      // return CanvasUtil.waterTxt2Canvs(cvs, waterImg, res.bottom, res.right)

      // console.log("bottom: " + res.bottom + ", right: " + res.right)
      let waterTextObj = document.getElementsByClassName("waterText-input")[0]
      // console.log("waterTextObj.clientHeight: " + waterTextObj.clientHeight + "")
      if (this.selColor == '') {
        this.selColor = "#000000"
      }
      style.color = '#1e90ff'
      return CanvasUtil.waterTxt2Canvs(cvs, words, res.bottom, res.right, style)
    },
    doMarkPre() {
      this.usrOprt("用户在文字水印界面点击'打水印'按钮")
      this.markWords = this.markWords.replace(" ", "")
      if (this.markWords == '' || this.markWords == undefined) {
        MessageUtils.rightNotify(this, "警告", "请填写水印文字", "warning")
        window.scrollTo(50, 0)
        return false
      }
      if (this.markWords.length > 50) {
        MessageUtils.rightNotify(this, "警告", "水印文字不能大于50个字", "warning")
        return false
      }
      if (this.imageDataList.length < 1) {
        MessageUtils.rightNotify(this, "警告", "请添加需要打水印的图片", "warning")
        return false
      }
      // this.$refs['verifyBox'].showVerifyBox()
      this.makeWater()

    },
    copyNoLogoList() {
      ArrayUtil.emptyList(this.imageDataNoLogoList)
      ArrayUtil.copyList(this.imageDataList, this.imageDataNoLogoList)
      ArrayUtil.emptyList(this.imageDataMarkLogoList)
      ArrayUtil.copyList(this.imageDataList, this.imageDataMarkLogoList)
    },
    imageDataProcess() {
      this.imageDataList.forEach(it => {
        let iStr = it.imageStr
        const img = new Image()
        img.src = iStr
        let cvs = CanvasUtil.imgToCanvas(img)
        // console.log("image width: " + res.width)
        let finCvs = this.doTxtMark(cvs, this.markWords, null, null)
        // 加网站logo
        // finCvs = this.doTxtMarkLogo(finCvs, "道森", null, null)
        let im = finCvs.toDataURL()
        // write back to img list
        it.imageStr = im
        // this.imageDataSrcList.push(im)
        console.log("ImageUtil.getSizeOfImage 还在执行")
        // this.imageProcessCount ++
      })
    },
    imageDataProcess2() {
      this.imageDataNoLogoList.forEach(it => {
        let iStr = it.imageStr
        const img = new Image()
        img.src = iStr
        let cvs = CanvasUtil.imgToCanvas(img)
        // console.log("image width: " + res.width)
        let finCvs = this.doTxtMark(cvs, this.markWords, null, null)
        // 不加网站logo
        // finCvs = this.doTxtMarkLogo(finCvs, "道森", res.width, res.height)
        let im = finCvs.toDataURL()
        // write back to img list
        it.imageStr = im
        // this.imageDataSrcList.push(im)
        console.log("ImageUtil.getSizeOfImage 还在执行")
        // this.imageProcessCount ++
      })
    },
    async makeWater() {
      if (!this.makeWaterFlag) {
        MessageUtils.rightNotify(this, "警告", "请勿频繁操作", "warning")
        return false
      } else {
        this.makeWaterFlag = false
      }
      // this.topLoading = true
      // ArrayUtil.emptyList(this.imageDataSrcList)
      // 从this.imageDataNoLogoList获取

      // if (this.imageDataNoLogoList.length > 0) {
      //   console.log("##### this.imageDataNoLogoList.length: " + this.imageDataNoLogoList.length)
      //   ArrayUtil.emptyList(this.imageDataList)
      //   ArrayUtil.copyList(this.imageDataNoLogoList, this.imageDataList)
      //   console.log("##### this.imageDataList.length: " + this.imageDataList.length)
      // }
      this.imageProcessCount = 0
      this.imageDataProcess()
      // console.log("imageDataNoLogoList: " + this.imageDataNoLogoList.length + ", this.imageDataList.length: " + this.imageDataList.length)
      // while (true) {
      //   if (this.imageProcessCount < this.imageDataList.length) {
      //     console.log("imageDataNoLogoList: " + this.imageDataNoLogoList.length + ", this.imageDataList.length: " + this.imageDataList.length)
      //     await CommonUtil.sleep(500)
      //     continue
      //   } else {
      //     break;
      //   }
      // }
      // console.log("imageDataProcess 处理完毕 ")
      // this.imageDataProcess2()
      // this.copyNoLogoList()
      // console.log("this.copyNoLogoList() 已经执行")
      // this.imageProcessCount = 0
      // await CommonUtil.sleep(500)
      // this.imageDataProcess2()
      // console.log("imageDataNoLogoList: " + this.imageDataNoLogoList.length + ", this.imageDataList.length: " + this.imageDataList.length)
      // while (true) {
      //   if (this.imageProcessCount < this.imageDataNoLogoList.length) {
      //     await CommonUtil.sleep(500)
      //     console.log("imageDataNoLogoList: " + this.imageDataNoLogoList.length + ", this.imageDataList.length: " + this.imageDataList.length)
      //     continue
      //   } else {
      //     break;
      //   }
      // }
      this.marked = 1
      // 一次打水印结束才能进行下一次打水印
      await CommonUtil.sleep(1000)
      this.makeWaterFlag = true
      this.showImageBigStr()
      MessageUtils.rightNotify(this, "成功", "水印制作完成", "success")
      // this.topLoading = false
    },
    attachFile2CurrDoc() {
      this.$refs.myWaterFile.click()
      this.$refs.myWaterFile.value = ""
    },
    deleteCurrPage(pageNo) {
      let newImageDataList = []
      this.imageDataList.forEach((it, index) => {
        if (index == pageNo) {
          // do nothing
        } else {
          newImageDataList.push(it)
        }
      })
      this.imageDataList = newImageDataList
      // No Logo
      let newImageDataNoLogoList = []
      this.imageDataNoLogoList.forEach((it, index) => {
        if (index == pageNo) {
          // do nothing
        } else {
          newImageDataNoLogoList.push(it)
        }
      })
      this.imageDataNoLogoList = newImageDataNoLogoList
      // 刷新大图
      this.showImageBigStr()
    },
    verifyImageLength(fL) {
      if (this.imageDataList.length + fL >= 20) {
        MessageUtils.rightNotify(this, "警告", "图片不能超过20张", "warning")
        return false
      }
      return true
    },
    previewFiles() {
      // 上传图片预览
      let files = this.$refs.myWaterFile.files
      if ( ! this.verifyImageLength(files.length)) {
        return false;
      }
      console.log("file: " + files + ", files length: " + files.length)
      if (files == undefined || files.length < 1) {
        console.error("file is empty")
        return ;
      }
      for(let i = 0; i < files.length; i++) {
        let s = files[i]
        console.log("file: " + s)
        let img = new FileReader()
        img.readAsDataURL(s)
        // console.log("img: ", img)
        let imageItem = {}
        img.onload = ({ target }) => {
          imageItem.imageStr = target.result
          imageItem.seq = i
          ImageUtil.getSizeOfImage(imageItem.imageStr).then(function (res) {
            imageItem.width = res.width
            imageItem.height = res.height
            // console.log("imgTmp.width: " + res.width)

          }).then(res => {
            this.imageDataList.push(imageItem)
            this.imageDataNoLogoList.push(JSON.parse(JSON.stringify(imageItem)))
            // this.imageDataNoLogoList.push(imageItem)
            // 重置水印功能
            this.bckImageDateList.push(JSON.parse(JSON.stringify(imageItem)))
            // this.imageDataSrcList.push(imageItem.imageStr)
          }).then(res => {
            this.showImageBigStr()
          })

        }
      }
    }
  }
}

</script>

<style>

.img-item-box {
  margin-right: 2px;
  margin-bottom: 5px;
  width: 19%;
  height: 510px;
  display: inline;
  float: left;
  border: 2px #cccccc solid;
  background-color: #cccccc;
  border-radius: 5px;
}


.img-item-box-add {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 20%;
  height: 510px;
  display: inline;
  float: left;
  border: 2px #cccccc solid;
  background-color: #ffffff;
}

白.img-item-box span {
  width: 100%;
  display: block;
  height: 30px;
  padding-top: 5px
}

.pdf-image-box {
//z-index: 1000;
  border-top: 2px #cccccc dashed;
}

</style>