<template>
  <div class="goods-image">
    <!-- Large image -->
    <div :class='`large large${docIndex}`' :style='[{backgroundImage: `url(${image})`}, bgPosition]' v-show='isShow'>
    </div>
    <div ref='target'>
      <!-- Left image -->
      <img @mouseenter="refreshPos" :style="styles" id="leftImage" :src="image" alt="">
      <!-- cover layer-->
      <div class="layer" :style='[position]' v-show='isShow'></div>
    </div>
  </div>
</template>

<script>

import {ref, watch, reactive} from 'vue'
import {useMouseInElement} from '@vueuse/core'

export default {

  name: 'EnlargeImage',
  props: {
    image: {
      type: String, default: () => ''
    },
    LargeLeft: {
      type: String,
      default: () => ''
    },
    styles: {
      type: String,
      default: () => ''
    },
    docIndex: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      LeftGap: '',
      TargeWidth: '',
      LargeHeight: '',
      bufferDocIndex: ''
    }
  },
  created() {
  },
  methods: {
    refreshPos(e) {
      console.log("docIndex: " + this.docIndex)
      const width = e.target.width
      const height = e.target.height
      const top = e.target.offsetTop
      console.log(width + "，" + height + ", top: " + top)
      const elements = document.getElementsByClassName('large' + this.docIndex);
      console.log("elements size: " + elements.length)
      Array.prototype.forEach.call(elements, function (s, index) {
        s.style.top = 5 + "px"
        if ((index + 1) % 5 == 0) {
          s.style.left = e.target.offsetLeft - width - 12 + "px"
        } else {
          s.style.left = e.target.offsetLeft + width + 5 + "px"
        }
        s.style.width = width + "px"
        s.style.height = height + "px"
        console.log("image width: " + s.style.width + " height: " + s.style.height)
        console.log("background size: " + width * 4 + "px " + height * 4 + "px")
        s.style.backgroundSize = width * 4 + "px " + height * 4 + "px"
      });
    }
  },
  setup(props) {
    const currIndex = ref(0)
    const target = ref(null)
    const isShow = ref(false)
    // 遮置层的坐标
    const position = reactive({
      left: 0,
      top: 0
    })
    // 控制背景图的位置
    const bgPosition = reactive({
      backgroundPositionX: 0,
      backgroundPositionY: 0
    })

    const {elementX, elementY, isOutside} = useMouseInElement(target)// 侦听鼠标移动后信息

    watch([elementX, elementY, isOutside], () => {
      // 每次有值发生变化，就读取新的数据即可
      isShow.value = !isOutside.value
      // 鼠标在图片的区域之外，不需要计算坐标
      if (isOutside.value) return
      // 水平方向
      position.left = elementX.value
      position.top = elementY.value
      console.log("### elementX: " + elementX.value + ", elementY: " + elementY.value)
      // 计算预览大图背景的位置
      bgPosition.backgroundPositionX = -position.left * 3 + 'px'
      bgPosition.backgroundPositionY = -position.top * 3 + 'px'

      console.log(position.left + ", position.top: " + position.top)
      console.log("backgroundPositionX: " + bgPosition.backgroundPositionX + ", backgroundPositionY: " + bgPosition.backgroundPositionY)

      position.left += 'px'
      position.top += 'px'

    })
    return {currIndex, target, isShow, position, bgPosition}
  }
}

</script>

<style scoped Lang="less">
.goods-image {
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
  }
}

.large {
  position: absolute;
  top: 0;
  z-index: 1400;
  box-shadow: 0 10px rgba(0, 0, 0, 0.1);
  //border: #2c3e50 dashed 4px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
}

.middle {
  background: #f5f5f5;
  position: relative;
  cursor: move;

  .layer {
    z-index: 1000;
    width: 200px;
    height: 200px;
    background: rgba(o, 0, 0, 0.2);
    left: 0;
    top: 0;
    position: absolute;
  }
}

.small {
  margin: 0;
  padding: 0;
  width: 80px;

  li {
    width: 68px;
    height: 68px;
    margin: 10px;
    list-style: none;
    cursor: pointer;

    &:hover,
    &.active {
      border: 2px solid #27ba9b;
    }
  }
}


</style>