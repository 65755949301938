import Vue from 'vue';
import VueRouter from 'vue-router';
import AiCard from "@/views/AiCard.vue";
import WaterMark from "@/views/water-mark/WaterMark.vue";
import JiHuo from "@/views/jihuo-guide/JiHuo";
import IdeaJiHuo from "@/views/jihuo-guide/idea-jihuo"
import BlogList from "@/views/blog/my-blog";

Vue.use(VueRouter)


const routes = [
    {
        path: '/ai-card',
        name: 'ai证件照',
        component: AiCard
    },
    {
        path: '/water-mark',
        name: '水印工具',
        component: WaterMark
    },
    {
        path: '/jihuo',
        name: '激活教程',
        component: JiHuo
    },
    {
        path: '/idea-jihuo',
        name: 'Idea激活教程',
        component: IdeaJiHuo
    },
    {
        path: '/blog-list',
        name: '博客列表',
        component: BlogList
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/indi') {
        let token = localStorage.getItem('Authorization')
        console.log("no token")
        if (token === null || token === '') {
            // next('/login')
            window.location = "https://cn.hongkezhang.com/html/auth/login.html?oriHref=" + window.location.href
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router