import http from '@/request/http'
import process from 'process'
const env = process.env

export default {

    async usrOprt(params) {
        let data
        await http.postWithOuterGivenUrl(env.VUE_APP_BASE_USER_URL, "/gene/usrOprt/save", params).then(response => {
            data = response.data
            console.log("res data: " + data)
        })
        return data
    },

    async auth(params) {
        let data
        await http.postWithOuterGivenUrl(env.VUE_APP_BASE_USER_URL, "/gene/user/auth", params).then(response => {
            data = response.data
            console.log("res data: " + data)
        })
        return data
    },

    async loginout(params) {
        let data
        await http.postWithOuterGivenUrl(env.VUE_APP_BASE_USER_URL, "/gene/user/loginout", params).then(response => {
            data = response.data
            console.log("res data: " + data)
        })
        return data
    },

}