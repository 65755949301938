export default {

    emptyList(dataList) {
        const length = dataList.length
        for(let i = 0; i < length; i++) {
            dataList.pop()
        }
    },

    copyList(srcList, tgtList) {
        for(let i = 0; i < srcList.length; i++) {
            tgtList.push(JSON.parse(JSON.stringify(srcList[i])))
        }
    }

}