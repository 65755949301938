'use strict'

import axios from 'axios'
import qs from 'qs'
import process from 'process'
import router from "@/router"
import MessageUtils from "@/utils/MessageUtils";
const env = process.env
axios.defaults.baseURL = 'http://127.0.0.1:8109/'
axios.defaults.timeout = 50000

if (env.NODE_ENV != '') {
    console.log("env.NODE_ENV: " + env.NODE_ENV)
    axios.defaults.baseURL = env.VUE_APP_BASE_URL
    console.log("env.VUE_APP_BASE_URL: " + env.VUE_APP_BASE_URL)
}

axios.interceptors.request.use( config => {
    const token = localStorage.getItem('Authorization')
    console.log("################ token: " + token)
    if (token) {
        config.headers.Authorization = token
    }
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {
    console.log("### Error:" + response.status)
    if (response.status === 403) {
        // this.store.dispatch('/ei/login').then(() => {
        //     location.reload()
        // })
        window.location = "https://cn.hongkezhang.com/html/auth/login.html?oriHref=" + window.location.href
    }
    return response
}, error => {
    console.log("### Error with: " + error)
    return Promise.resolve(error.response)
})

function checkStatus(response) {
    if (response.status === 403) {
        // return router.push("/ei/login")
        window.location = "https://cn.hongkezhang.com/html/auth/login.html?oriHref=" + window.location.href
    }
    // Loading
    // if http code is normal, directly return data
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)){
        // if no need data except data object, directly return response.data
        return response
    }
    // or return abnormal status
    return {
        status: -404,
        msg: 'network error'
    }
}

export default {
    post (url, data) {
        return axios({
            method: 'post',
            url,
            data: JSON.stringify(data),
            // timeout: 30000
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json; charset=UTF-8'
            }
        }).then(
            (response) => {
                return checkStatus(response)
            })
    },
    postWithOuterGivenUrl (baseUrl, url, data) {
        console.log("postWithOuterGivenUrl base url: " + baseUrl)
        url = 'https://cn.hongkezhang.com' + url
        return axios({
            method: 'post',
            url,
            data: JSON.stringify(data),
            // timeout: 30000
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json; charset=UTF-8'
            }
        }).then(
            (response) => {
                return checkStatus(response)
            }).finally(
            () => {
            }
        )
    },
    postForm (url,data){
        return axios({
            method: 'post',
            url,
            data: data,
            // timeout:10000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'multipart/form-data'
            }
        }).then(
        (response ) => {
            return checkStatus(response)
        })
    },
    get(url, params) {
        return axios({
            method: 'get',
            url,
            params,
            headers: {
                'x-Requested-With': 'XMLHttpRequest'
            }
    }).then(
        (response) => {
            return checkStatus(response)
        })
    }
}

